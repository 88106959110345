


























































































import { Component, Vue } from "vue-property-decorator";
import { AppUser } from "@/models/user";
import { ApiEndPoints } from "@/helpers/api_endpoints";
import Notifier from "@/helpers/notifier";
import _axios from "@/plugins/axios";
import {
  ApplicationStatus,
  BusinessApplication,
} from "@/models/businessApplication";
import agent from "@/helpers/agent";
import { ProfileDocument } from "@/models/businessDocument";
import * as _ from "lodash";

@Component
export default class OrganizationsView extends Vue {
  private applications: BusinessApplication[] = [];
  pagination = {
    sortBy: "Name",
    descending: false,
    page: 0,
    rowsPerPage: 10,
    rowsNumber: 10,
  };
  loading = true;
  filter = "";

  idcopies: ProfileDocument[] = [];
  columns = [
    {
      name: "Name",
      required: true,
      label: "Name",
      align: "left",
      field: (row: BusinessApplication) => row.name,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "Reference",
      required: true,
      label: "Reference",
      align: "left",
      field: (row: BusinessApplication) => row.reference,
      sortable: true,
    },
    {
      name: "Status",
      required: true,
      label: "Status",
      align: "left",
      field: (row: BusinessApplication) => this.status(row.status),
      format: (val: string) => `${val}`,
      sortable: false,
    },
    {
      name: "actions",
      required: true,
      label: "Actions",
      align: "right",
      field: "Actions",
    },
  ];

  lockedIcon(row: AppUser): string {
    return row.lockoutEnd === null ? "lock" : "lock_open";
  }
  status(status: ApplicationStatus) {
    return _.startCase(ApplicationStatus[status]);
  }
  mounted() {
    this.onRequest({
      pagination: this.pagination,
      filter: "",
    });
  }
  onRequest(props: any = {}) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const search = props.filter;
    this.loading = true;
    // get all rows if "All" (0) is selected
    const size = rowsPerPage === 0 ? this.pagination.rowsNumber : rowsPerPage;
    agent.Applications.list(search, descending, page, size, sortBy).then(
      (rows) => {
        //populate table
        this.applications = rows.rows;
        this.pagination.rowsNumber = rows.rowCount;

        // don't forget to update local pagination object
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.pagination.sortBy = sortBy;
        this.pagination.descending = descending;

        // ...and turn of loading indicator
        this.loading = false;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  goToUpdate(id: string) {
    this.$router.push({
      name: "ProfilesApplication",
      query: { id },
    });
  }
  exportData() {
    const token = this.$store.getters["app/getToken"];
    return _axios
      .get(`api/${ApiEndPoints.financialInstitutions}/export-to-excel`, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/octet-stream",
          })
        );
        const fileLink = document.createElement("a");
        fileLink.href = url;
        fileLink.download = "Organizations.xlsx";
        document.body.appendChild(fileLink);
        fileLink.click();
      });
  }
  deleteApplication(id: string) {
    agent.Applications.delete(id).then(() => {
      const index = this.applications.findIndex(
        (i) => i.businessApplicationID == id
      );
      this.applications.splice(index, 1);
      Notifier.showInfo(`Successfully deleted`);
    });
  }
}
